<template>
  <be-list-group flush>
    <be-list-group-item v-if="platformEnabled('onboarding_bankid')" class="p-4">
      <task-container
        action-key="bank_id_registered"
        section-type="user_info"
        :onboarding-object="localUser"
        :hide-button-on-completion="true"
        :skipped="isSkipped('user_info', 'bank_id_registered')"
        :title="$t('bankid.activate_bank_id')"
      >
        <template #text>
          <div class="mb-2">
            {{
              $t(
                "components.onboarding.introduction.user_section.activate_bankid_description"
              )
            }}
          </div>

          <ul>
            <li>
              {{
                $t(
                  "components.onboarding.introduction.user_section.activate_bankid_list_item_one"
                )
              }}
            </li>

            <li>
              {{
                $t(
                  "components.onboarding.introduction.user_section.activate_bankid_list_item_two"
                )
              }}
            </li>
          </ul>
        </template>

        <template #link>
          <div>
            <div class="d-none d-md-inline-block">
              <be-link v-be-modal.bankid>
                {{ $t("buttons.titles.activate") }}
              </be-link>

              <p>
                <be-link
                  v-if="
                    !localUser.identification_number_verified &&
                    !isSkipped('user_info', 'bank_id_registered')
                  "
                  @click="$emit('bank-id-skipped')"
                >
                  <span class="text-muted small">
                    {{ $t("buttons.titles.skip") }}
                  </span>
                </be-link>
              </p>
            </div>

            <be-button
              v-be-modal.bankid
              class="mt-3 order-1 order-md-2 d-md-none"
              variant="outline-secondary"
              block
            >
              {{ $t("buttons.titles.activate") }}
            </be-button>

            <be-button
              v-if="
                !localUser.identification_number_verified &&
                !isSkipped('user_info', 'bank_id_registered')
              "
              variant="outline-secondary"
              class="d-md-none"
              block
              @click="$emit('bank-id-skipped')"
            >
              {{ $t("buttons.titles.skip") }}
            </be-button>
          </div>

          <be-modal
            id="bankid"
            :title="$t('bankid.activate_bank_id')"
            no-footer
          >
            <bank-id-authentication
              begin-authentication-on-mount
              :redirect-on-success="false"
              collect-url="/bank_id/collect/activation"
              @cancel="$beModal.hide('bankid')"
              @bankid-collect-success="activateBankID()"
            />
          </be-modal>
        </template>
      </task-container>
    </be-list-group-item>

    <be-list-group-item class="p-4">
      <task-container
        action-key="profile_picture_uploaded"
        section-type="user_info"
        :onboarding-object="localUser"
        :title="
          $t(
            'components.onboarding.introduction.user_section.upload_profile_picture'
          )
        "
      >
        <template #text>
          <div>
            {{
              $t(
                "components.onboarding.introduction.user_section.upload_profile_picture_description"
              )
            }}
          </div>

          <i18n-t
            v-if="
              ['boardeaser', 'boardeaser_new_brand'].includes(
                $platform.theme.theme_name
              )
            "
            keypath="components.onboarding.introduction.user_section.update_settings_description"
            tag="p"
            class="text-muted mt-3"
          >
            <template #link>
              <a :href="supportUserSettingsUrl" target="_blank">
                {{
                  $t(
                    "components.onboarding.introduction.user_section.support_guide"
                  )
                }}
                <i class="fal fa-external-link small" />
              </a>
            </template>
          </i18n-t>
        </template>

        <template #link>
          <be-link
            v-be-modal="'upload-profile-picture'"
            class="d-none d-md-inline-block"
          >
            {{ uploadImageText }}
          </be-link>

          <be-button
            v-be-modal="'upload-profile-picture'"
            class="d-md-none mt-3"
            variant="outline-secondary"
            block
          >
            {{ uploadImageText }}
          </be-button>

          <be-modal
            id="upload-profile-picture"
            :title="uploadImageText"
            :ok-disabled="uploadingFiles || !logoSelected"
            :ok-title="fileUploadButtonTitle"
            @ok="uploadProfilePicture"
          >
            <single-file-uploader
              :key="`user-${localUser.id}-image-${localUser.updated_at}`"
              v-model:remove="localUser.remove_image"
              :aws-url="awsUrl"
              :accepted-types="acceptedFileTypes"
              :file="localUser.image_attachment || {}"
              name="user[image]"
              @file-removed="fileRemoved"
              @file-updated="fileUpdated"
              @uploading="(value) => (uploadingFiles = value)"
            />
          </be-modal>
        </template>
      </task-container>
    </be-list-group-item>
  </be-list-group>
</template>

<script>
import TaskContainer from "@/components/onboarding/TaskContainer.vue";

import { IMAGE_TYPES } from "@/constants/file-types";

export default {
  components: {
    TaskContainer,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },

    awsUrl: {
      type: String,
      required: true,
    },
  },

  emits: ["bank-id-activated", "bank-id-skipped"],

  data() {
    return {
      uploadingFiles: false,
      logoSelected: false,
      localUser: {},
    };
  },

  computed: {
    acceptedFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },

    fileUploadButtonTitle() {
      return this.uploadingFiles
        ? this.$i18n.t("buttons.states.loading")
        : this.$i18n.t("buttons.titles.upload");
    },

    uploadImageText() {
      if (this.localUser?.progress?.user_info?.profile_picture_uploaded) {
        return this.$t("components.onboarding.change_image");
      } else {
        return this.$t("buttons.titles.upload");
      }
    },

    supportUserSettingsUrl() {
      if (this.$currentUser.locale === "sv") {
        return "https://support.boardeaser.com/hc/sv/articles/360021233440-Hur-%C3%A4ndrar-jag-mina-personliga-inst%C3%A4llningar-";
      }

      return "https://support.boardeaser.com/hc/en-gb/articles/360021233440-How-do-I-change-my-personal-settings-";
    },
  },

  watch: {
    user: {
      handler(newValue) {
        this.localUser = newValue;
      },

      deep: true,
    },
  },

  mounted() {
    this.logoSelected = !!this.localUser.image_attachment;
  },

  methods: {
    activateBankID() {
      this.$emit("bank-id-activated");

      this.$beModal.hide("bankid");
    },

    async updateUser(updatedUser) {
      this.localUser = this.cloneDeep(updatedUser);
      this.$store.commit("current_user/setUser", this.localUser);
    },

    isSkipped(sectionKey, actionKey) {
      return this.localUser.onboarding_skipped_steps?.includes(
        `${sectionKey}.${actionKey}`
      );
    },

    fileUpdated(file) {
      this.localUser.image_attachment = file;
      this.localUser.image = file;

      this.logoSelected = true;
      this.uploadingFiles = false;
    },

    async fileRemoved() {
      this.localUser.image = null;
      this.localUser.image_attachment = null;

      try {
        const response = await axios.patch("/users/profile", {
          user: this.localUser,
        });

        this.updateUser(response.data);

        this.logoSelected = false;
      } catch (error) {
        this.handleError(error);
      }
    },

    async uploadProfilePicture() {
      try {
        const response = await axios.patch("/users/profile", {
          user: this.localUser,
        });

        this.updateUser(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
